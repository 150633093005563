<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formTransparencyTypes" @submit.prevent="saveForm">
        <v-row v-if="type == 'table'">
          <v-col cols="12" md="3">
            <label for="period">Periodo</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="period"
              type="number"
              autocomplete="off"
              v-model="payload['period']"
              outlined
              dense
              placeholder="Periodo"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="area">Área</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="area"
              autocomplete="off"
              v-model="payload['area']"
              outlined
              dense
              placeholder="Área"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="name">Nombre</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="name"
              autocomplete="off"
              v-model="payload['name']"
              outlined
              dense
              placeholder="Nombre"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <label for="description">Descripción</label>
          </v-col>

          <v-col cols="12" md="12" class="pb-0">
            <v-textarea
              id="description"
              v-model="payload['description']"
              outlined
              dense
              placeholder="Descripción"
              :rules="required"
            ></v-textarea>
          </v-col>

          <v-col cols="12" md="3">
            <label for="name">Fecha (opcional)</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="custom_date"
              autocomplete="off"
              v-model="payload['custom_date']"
              outlined
              dense
              placeholder="Nombre"
              type="date"
              :rules="[]"
            ></v-text-field>
          </v-col>

          <v-col v-if="!transparencyContentEdited" cols="12" md="3">
            <label for="file_upload">Orden predeterminado</label>
          </v-col>
          <v-col v-if="!transparencyContentEdited" cols="12" md="9" class="pb-0">
            <v-select
              v-model="payload['init_order']"
              outlined
              dense
              :items="orders"
              item-text="name"
              item-value="val"
              placeholder="Elija una opción de orden predeterminado"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="3">
            <label for="img_about">Archivo Adjunto</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="img_about"
              v-model="payload['file_upload']"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige el archivo adjunto"
            ></v-file-input>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="type == 'cards'">
          <v-col cols="12" md="3">
            <label for="name">Nombre</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="name"
              autocomplete="off"
              v-model="payload['name']"
              outlined
              dense
              placeholder="Nombre"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="file_cards">Imagen</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="file_cards"
              v-model="payload['file_cards']"
              outlined
              prepend-icon=""
              dense
              placeholder="Elija una imagen"
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="3">
            <label for="file_upload">Archivo Adjunto</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="file_upload"
              v-model="payload['file_upload']"
              outlined
              prepend-icon=""
              dense
              placeholder="Elija un el archivo adjunto"
            ></v-file-input>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
  },
  props: {
    transparencyContentEdited: Object,
    subtypeId: String,
    type: String,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      payload: this.transparencyContentEdited
        ? { ...this.transparencyContentEdited }
        : {
            init_order: 'start',
          },
      orders: [
        {
          name: 'Colocar al inicio',
          val: 'start',
        },
        {
          name: 'Colocar al final',
          val: 'end',
        },
      ],
    }
  },

  created() {
    // console.log('hola')
  },
  methods: {
    validateForm() {
      return this.$refs.formTransparencyTypes.validate()
    },
    reset() {
      this.$refs.formTransparencyTypes.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.transparencyContentEdited) {
        let DTO = { ...this.payload, id_subtypes: this.subtypeId }
        // // console.log('actualizar', DTO)
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.payload, id_subtypes: this.subtypeId }
        this.$emit('beforeCreate', DTO)
      }
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  max-width: 450px;
  max-height: 450px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section {
  margin: 0 auto;
  max-width: 900px;
}
</style>
