<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formTransparencySubTypes" @submit.prevent="saveForm">
        <v-row v-if="type == 'header'">
          <v-col cols="12" md="3">
            <label for="title">Subtítulo</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              autocomplete="off"
              id="title"
              v-model="payload['title']"
              outlined
              dense
              placeholder="Añade el subtítulo"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="type == 'content'">
          <v-col cols="12" md="3">
            <label for="name">Nombre</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              autocomplete="off"
              id="name"
              v-model="payload['name']"
              outlined
              dense
              placeholder="Nombre del archivo"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label for="img_about">Archivo Adjunto</label>
          </v-col>

          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="img_about"
              v-model="payload['file_upload']"
              outlined
              prepend-icon=""
              dense
              placeholder="Elige el archivo adjunto"
            ></v-file-input>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
  },
  props: {
    transparencyContentEdited: Object,
    loading: Boolean,
    idTypes: String,
    type: String,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      payload: this.transparencyContentEdited ? { ...this.transparencyContentEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formTransparencySubTypes.validate()
    },
    reset() {
      this.$refs.formTransparencySubTypes.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      // // console.log(this.payload)

      if (this.transparencyContentEdited) {
        let DTO = { ...this.payload }
        this.$emit('beforeUpdate', DTO)
      } else {
        // // console.log(this.payload)
        const DTO = { ...this.payload }
        this.$emit('beforeCreate', DTO)
      }
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
