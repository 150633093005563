import { ref, computed } from "@vue/composition-api";
import store from "@/store/index";
import { getNewOrderEl } from "@/utils/order";


const useTransparencySubtypesTypes = (idSubtype) => {

    // base data
    const dialog = ref(false);
    const type = ref('')
    const transparencyContentEdited = ref(null);
    const transparencyContent = ref([]);
    const idContent = ref("");
    const search = ref("");
    const loading = ref(false);
    const baseIndex = ref(132656)


    // computed properties
    const titleDialog = computed(() =>
        transparencyContentEdited.value ? "Editar Contenido de Transparencia" : "Agregar Contenido de Transparencia"
    );
    const subtitleDialog = computed(() =>
        transparencyContentEdited.value ?
            "Rellene los campos correctamente para modificar los datos del Contenido de Transparencia" :
            "Rellene los campos correctamente para validar los datos del Contenido de Transparencia"
    );

    const openDialog = (typeValue, idContentValue = "") => {
        idContent.value = idContentValue;
        type.value = typeValue;
        dialog.value = true;
    }
    const openEdit = (transparencyContent, typeValue, idContentValue = "") => {
        // console.log(transparencyContent, typeValue, idContentValue)
        idContent.value = idContentValue;
        type.value = typeValue;
        dialog.value = true;
        transparencyContentEdited.value = { ...transparencyContent };
    }
    const closeDialog = () => {
        idContent.value = "";
        dialog.value = false;
        type.value = "";
        transparencyContentEdited.value = null;
    }

    const getTransparencyListBySubtype = async () => {
        loading.value = true;
        const { data, status } = await store.dispatch('getTransparencyContentList', idSubtype);
        loading.value = false;
        transparencyContent.value = data;
    }

    const create = async (content) => {
        switch (type.value) {
            case 'header':
                await createTransparencyListHeaderSubtype(content)
                break;
            case 'content':
                await createTransparencyListSubtype(content)
                break;
        }
    }


    const createTransparencyListHeaderSubtype = async (content) => {
        loading.value = true;
        const { data, status } = await store.dispatch('createTransparencyContentListHeader', { ...content, id_subtypes: idSubtype });
        loading.value = false;
        closeDialog();
        getTransparencyListBySubtype();
    }

    const createTransparencyListSubtype = async (content) => {
        loading.value = true;
        const { data, status } = await store.dispatch('createTransparencyContentList', { ...content, id_content: idContent.value });
        loading.value = false;
        closeDialog();
        getTransparencyListBySubtype();
    }


    const update = async (content) => {
        switch (type.value) {
            case 'header':
                await updateTransparencyListHeaderSubtype(content)
                break;
            case 'content':
                await updateTransparencyListSubtype(content)
                break;
        }
    }

    const updateTransparencyListHeaderSubtype = async (content) => {
        loading.value = true;
        const { data, status } = await store.dispatch('updateTransparencyContentListHeader', { ...content, id_subtypes: idSubtype });
        loading.value = false;
        closeDialog();
        getTransparencyListBySubtype();
    }

    const updateTransparencyListSubtype = async (content) => {
        loading.value = true;
        const { data, status } = await store.dispatch('updateTransparencyContentList', { ...content, id_content: idContent.value });
        loading.value = false;
        closeDialog();
        getTransparencyListBySubtype();
    }

    const remove = async (content, typeValue) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        switch (typeValue) {
            case 'header':
                await removeTransparencyListHeaderSubtype(content)
                break;
            case 'content':
                await removeTransparencyListSubtype(content)
                break;
        }
    }

    const removeTransparencyListHeaderSubtype = async (content) => {
        loading.value = true;
        const { data, status } = await store.dispatch('removeTransparencyContentListHeader', content);
        loading.value = false;
        getTransparencyListBySubtype();
    }

    const removeTransparencyListSubtype = async (content) => {
        loading.value = true;
        const { data, status } = await store.dispatch('removeTransparencyContentList', content);
        loading.value = false;
        getTransparencyListBySubtype();
    }

    const handleSort = (a, b) => parseFloat(b.order) - parseFloat(a.order);

    const sortTransparencyContent = () => {
      transparencyContent.value.sort(handleSort)
    }

    const updateOrderOnList = (obj) => {
      const idx = transparencyContent.value.findIndex(item => item.id == obj.id)
      if (idx > -1) {
        transparencyContent.value.splice(idx, 1, obj)
      }
      sortTransparencyContent()
    }

    const sortTransparencySubListContent = (listId) => {
      const idx = transparencyContent.value.findIndex(item => item.id == listId)
      transparencyContent.value[idx].content.sort(handleSort)
    }

    const updateOrderOnSubList = (listId, obj) => {
      const parentIndex = transparencyContent.value.findIndex(item => item.id == listId)
      if (parentIndex == -1) return
      const idx = transparencyContent.value[parentIndex].content.findIndex(item => item.id == obj.id)
      if (idx > -1) {
        transparencyContent.value[parentIndex].content.splice(idx, 1, obj)
      }
      sortTransparencySubListContent(listId)
    }

    const onDropCallback = async (ev) => {
      try {
        const orderObj = getNewOrderEl(ev, transparencyContent.value)
        if (!orderObj) return
        const { data, status } = await store.dispatch('updateTransparencyContentListHeader', orderObj)
        if (status == 200 || status != 201) {
          updateOrderOnList(orderObj)
          return
        }
        sortTransparencyContent()
      } catch (error) {
        sortTransparencyContent()
      }
    }

    const onDropCallbackSubList = async (ev, list, listId) => {
      try {
        const orderObj = getNewOrderEl(ev, list)
        if (!orderObj) return
        const { data, status } = await store.dispatch('updateTransparencyContentList', orderObj)
        if (status == 200 || status != 201) {
          updateOrderOnSubList(listId, data)
          return
        }
        sortTransparencySubListContent(listId)
      } catch (error) { }
    }


    getTransparencyListBySubtype();

    return {
        dialog,
        transparencyContentEdited,
        transparencyContent,
        search,
        loading,
        type,
        // computed
        titleDialog,
        subtitleDialog,
        openDialog,
        closeDialog,
        openEdit,
        // methods
        create,
        update,
        openRemove: remove,
        onDropCallback,
        onDropCallbackSubList

    };
}

export default useTransparencySubtypesTypes;
