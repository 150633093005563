<template>
  <v-data-table
    :headers="headers"
    :search="search"
    :items="items"
    :loading="loading"
    item-key="name"
    class="table-rounded datatable-height"
    fixed-header
    disable-sort
    :key="baseIndex"
  >
    <template v-slot:body="props">
      <draggable
        :list="props.items"
        tag="tbody"
        handle=".dragg-item"
        :animation="200"
        ghost-class="ghost-drag-item"
        @end="onDropCallback"
      >
        <data-table-row-handler v-for="(item, index) in props.items" :key="index" :item="item" :headers="headers">
          <template #[`item.dragg`]="{}">
            <v-btn class="action-btn dragg-item" title="Mover" @click="() => {}" small icon>
              <svg style="width: 20px; height: 20px" width="24" height="24" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M4 22h-4v-4h4v4zm0-12h-4v4h4v-4zm0-8h-4v4h4v-4zm3 0v4h17v-4h-17zm0 12h17v-4h-17v4zm0 8h17v-4h-17v4z"
                />
              </svg>
            </v-btn>
          </template>
          <template #[`item.order`]="{}">
            <span>{{ index + 1 }}</span>
          </template>
          <template #[`item.upload`]="{ item }">
            <a :href="loadImage(item.upload)" target="_blank" icon>
              <v-icon>{{ icons.mdiDownload }}</v-icon>
            </a>
          </template>
          <template #[`item.name`]="{ item }">
            <div class="d-flex flex-column">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
            </div>
          </template>
          <template #[`item.description`]="{ item }">
            <p class="short-description" :title="item.description">
              {{ item.description }}
            </p>
          </template>
          <template #[`item.actions`]="{ item }">
            <div class="d-flex">
              <CrudButtons 
                @edit="$emit('edit', item)"
                @remove="$emit('remove', item)"
                @sendToTheEnd="$emit('sendToTheEnd', {...item, init_order: 'end'})"
                @sendToTheInit="$emit('sendToTheInit', {...item, init_order: 'start'})"
                draggControls
              ></CrudButtons>

            </div>
          </template>
        </data-table-row-handler>
      </draggable>
    </template>
  </v-data-table>
</template>

<script>
import CrudButtons from '@/components/buttons/CrudButtons.vue'
import { mdiDownload } from '@mdi/js'
import { loadAPIImage } from '@/utils/images'
import draggable from 'vuedraggable'
import DataTableRowHandler from '@/components/data-table/DataTableRowHandler.vue'
import { getNewOrderEl } from '@/utils/order'
import { mapActions } from 'vuex'

export default {
  components: {
    CrudButtons,
    DataTableRowHandler,
    draggable,
  },
  props: {
    items: Array,
    search: String,
    loading: Boolean,
  },
  data() {
    return {
      icons: {
        mdiDownload,
      },
      baseIndex: 15230,
      headers: [
        { text: '', value: 'dragg' },
        { text: 'ORDEN', value: 'order' },
        { text: 'PERIODO', value: 'period' },
        { text: 'AREA', value: 'area' },
        { text: 'NOMBRE', value: 'name' },
        { text: 'DESCRIPCION', value: 'description' },
        { text: 'FECHA', value: 'custom_date' },
        { text: 'ARCHIVO', value: 'upload' },
        { text: '', value: 'actions', align: 'right' },
      ],
    }
  },
  methods: {
    ...mapActions({
      updateTransparencyContentTable: 'updateTransparencyContentTable',
    }),
    loadImage(src) {
      return loadAPIImage(src)
    },
    async onDropCallback(ev) {
      try {
        const orderObj = getNewOrderEl(ev, this.items)
        if (!orderObj) return
        const { data, status } = await this.updateTransparencyContentTable(orderObj)
        if (status == 200 || status != 201) {
          this.$emit('orderUpdated', orderObj)
        }
      } catch (error) {
        this.baseIndex += 50 // on error
      }
    },
  },
}
</script>

<style>
</style>