<template>
  <div>
    <div class="mx-5 my-2">
      <draggable
        :list="transparencyContent"
        :animation="200"
        tag="div"
        handle=".dragg-item-list"
        ghost-class="ghost-drag-item"
        @end="onDropCallback"
      >
        <div v-for="item in transparencyContent" :key="item.id">
          <div class="my-3 list-subtitle-block">
            <div class="d-flex align-items-center">
              <v-btn class="action-btn dragg-item-list mr-2" title="Mover" @click="() => {}" small icon>
                <svg style="width: 20px; height: 20px" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M4 22h-4v-4h4v4zm0-12h-4v4h4v-4zm0-8h-4v4h4v-4zm3 0v4h17v-4h-17zm0 12h17v-4h-17v4zm0 8h17v-4h-17v4z"/></svg>
              </v-btn>
              <h3 class="list-subtitle">{{ item.title }}</h3>
            </div>
            <div>
              <v-btn @click="openEdit(item, 'header')" icon class="ml-2">
                <v-icon size="24">{{ icons.mdiPencil }}</v-icon>
              </v-btn>
              <v-btn @click="openRemove(item, 'header')" icon class="ml-2">
                <v-icon size="24">{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </div>
          </div>

          <!-- SubList -->
          <draggable
            :list="item.content"
            :animation="200"
            tag="div"
            handle=".dragg-item"
            ghost-class="ghost-drag-item"
            @end="(ev) => onDropCallbackSubList(ev, item.content, item.id)"
          >
            <div class="mb-3 mx-6 dragg-item" v-for="i in item.content" :key="i.id">
              <div class="d-flex justify-space-between">
                <div>
                  <span class="mr-6">{{ i.name }}</span>
                  <a :href="linkFile(i.upload)" target="_blank" icon>
                    <v-icon>{{ icons.mdiDownload }}</v-icon>
                  </a>
                </div>
                <div>
                  <v-btn @click="openEdit(i, 'content', item.id)" icon class="ml-2">
                    <v-icon size="24">{{ icons.mdiPencil }}</v-icon>
                  </v-btn>
                  <v-btn @click="openRemove(i, 'content')" icon class="ml-2">
                    <v-icon size="24">{{ icons.mdiDelete }}</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </draggable>
          <!-- End SubList -->

          <div>
            <span class="subtitle-2 mx-6">Añade un archivo</span>
            <v-btn @click="openDialog('content', item.id)" x-small fab class="ml-2">
              <v-icon small>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </div>
        </div>
      </draggable>

      <div>
        <span class="subtitle-1">Añade un nuevo subtítulo</span>
        <v-btn @click="openDialog('header')" class="ma-4" fab small>
          <v-icon> {{ icons.mdiPlus }} </v-icon>
        </v-btn>
      </div>
    </div>
    <WDialog width="600" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormTransparencyList
          v-if="dialog"
          :loading="loading"
          :type="type"
          :transparencyContentEdited="transparencyContentEdited"
          @beforeCreate="create"
          @beforeUpdate="update"
        ></FormTransparencyList>
      </template>
    </WDialog>
  </div>
</template>


<script>
import FormTransparencyList from '@/components/forms/FormTransparencyList'
import useTransparencySubtypesList from '@/composables/useTransparencySubtypesList'
import WDialog from '@/components/dialogs/WDialog.vue'
import { loadAPIImage } from '@/utils/images'
import { mdiDownload, mdiPlus, mdiPencil, mdiDelete } from '@mdi/js'
import draggable from 'vuedraggable'
import { getNewOrderEl } from '@/utils/order'

export default {
  components: {
    FormTransparencyList,
    WDialog,
    draggable,
  },
  props: {
    content: Array,
    idSubtype: String,
  },
  setup(props) {
    const {
      dialog,
      transparencyContentEdited,
      transparencyContent,
      search,
      loading,
      type,
      // computed
      titleDialog,
      subtitleDialog,
      openDialog,
      closeDialog,
      openEdit,
      changeSearch,
      // methods
      create,
      update,
      openRemove,
      onDropCallback,
      onDropCallbackSubList,
    } = useTransparencySubtypesList(props.idSubtype)

    return {
      icons: {
        mdiDownload,
        mdiPlus,
        mdiPencil,
        mdiDelete,
      },
      dialog,
      transparencyContentEdited,
      transparencyContent,
      search,
      loading,
      type,
      // computed
      titleDialog,
      subtitleDialog,
      openDialog,
      closeDialog,
      openEdit,
      changeSearch,
      // methods
      create,
      update,
      openRemove,
      linkFile(src) {
        return loadAPIImage(src)
      },
      onDropCallback,
      onDropCallbackSubList,
    }
  },
}
</script>

<style>
.list-subtitle-block {
  display: flex;
  justify-content: space-between;
}
.list-subtitle-block .list-subtitle {
  color: #00aca0 !important;
}
</style>