<template>
  <v-card elevation="0">
    <HeaderView
      :title="transparencySubtype ? transparencySubtype.name : ''"
      @openToAdd="openDialog"
      @search="changeSearch"
      :noSearch="!transparencySubtype || transparencySubtype.type != 'table'"
      :noAdd="!transparencySubtype || transparencySubtype.type == 'list'"
    ></HeaderView>
    <Table
      v-if="transparencySubtype && transparencySubtype.type == 'table'"
      :loading="loading"
      :search="search"
      @edit="openEdit"
      @remove="removeTransparencyTableBySubtype"
      @sendToTheInit="updateTransparencyTableSubtype"
      @sendToTheEnd="updateTransparencyTableSubtype"
      @orderUpdated="updateTransparencyItemOnOrder"
      :items="transparencyContent"
    ></Table>
    <Card
      v-if="transparencySubtype && transparencySubtype.type == 'cards'"
      :loading="loading"
      @edit="openEdit"
      @remove="removeTransparencyCardsBySubtype"
      @orderUpdated="updateTransparencyItemOnOrder"
      :items="transparencyContent"
    ></Card>
    <List
      v-if="transparencySubtype && transparencySubtype.type == 'list'"
      :content="transparencyContent"
      :idSubtype="subtype"
    >
    </List>
    <WDialog width="600" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormTransparencyTypes
          v-if="dialog"
          :loading="loading"
          :type="transparencySubtype.type"
          :subtypeId="subtype"
          :transparencyContentEdited="transparencyContentEdited"
          @beforeCreate="createTransparencyContent"
          @beforeUpdate="updateTransparencyContent"
        ></FormTransparencyTypes>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import useTransparencySubtypesTypes from '@/composables/useTransparencySubtypesTypes'
import FormTransparencyTypes from '@/components/forms/FormTransparencyTypes.vue'
import WDialog from '@/components/dialogs/WDialog.vue'
import Table from './Table.vue'
import Card from './Card.vue'
import List from './List.vue'

import HeaderView from '@/components/HeaderView.vue'

export default {
  components: {
    HeaderView,
    Table,
    Card,
    List,
    WDialog,
    FormTransparencyTypes,
  },
  setup(props, context) {
    const route = context.root.$route
    const { type, subtype } = route.params

    const {
      dialog,
      transparencySubtype,
      transparencyContentEdited,
      transparencyContent,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      openDialog,
      closeDialog,
      openEdit,
      changeSearch,
      // methods
      createTransparencyContent,

      updateTransparencyContent,
      updateTransparencyTableSubtype,
      updateTransparencyItemOnOrder,

      removeTransparencyTableBySubtype,
      removeTransparencyListBySubtype,
      removeTransparencyCardsBySubtype,
    } = useTransparencySubtypesTypes(subtype)

    return {
      type,
      subtype,
      dialog,
      transparencySubtype,
      transparencyContentEdited,
      transparencyContent,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      openDialog,
      closeDialog,
      openEdit,
      changeSearch,
      // methods
      createTransparencyContent,

      updateTransparencyContent,
      updateTransparencyTableSubtype,
      updateTransparencyItemOnOrder,

      removeTransparencyTableBySubtype,
      removeTransparencyListBySubtype,
      removeTransparencyCardsBySubtype,
    }
  },
}
</script>

<style>
</style>