<template>
  <v-card-text>
    <v-data-iterator :items="items" :items-per-page="10" :page="1">
      <template v-slot:default="props">
        <v-row>
          <draggable
            class="row"
            :list="props.items"
            :animation="200"
            tag="div"
            handle=".dragg-item"
            ghost-class="ghost-drag-item"
            @end="onDropCallback"
          >
            <v-col v-for="item in props.items" :key="item.id" cols="12" lg="3" md="6" sm="12">
              <CardTransparencySubtypeType
                :item="item"
                :srcImage="loadImage(item.img_cards)"
                :linkFile="loadImage(item.upload)"
                @edit="$emit('edit', item)"
                @remove="$emit('remove', item)"
              ></CardTransparencySubtypeType>
            </v-col>
          </draggable>
        </v-row>
      </template>
    </v-data-iterator>
  </v-card-text>
</template>

<script>
import CardTransparencySubtypeType from '@/components/cards/CardTransparencySubtypeType.vue'
import { loadAPIImage } from '@/utils/images'
import draggable from 'vuedraggable'
import { mapActions } from 'vuex'
import { getNewOrderEl } from "@/utils/order"


export default {
  components: {
    CardTransparencySubtypeType,
    draggable
  },
  props: {
    items: Array,
    loading: Boolean,
  },
  methods: {
    ...mapActions({
      updateTransparencyContentCards: "updateTransparencyContentCards"
    }),
    loadImage(src) {
      return loadAPIImage(src)
    },
    async onDropCallback(ev) {
      try {
        const orderObj = getNewOrderEl(ev, this.items)
        if (!orderObj) return
        console.log(ev, orderObj);
        const { data, status } = await this.updateTransparencyContentCards(orderObj)
        if( status == 200 || status != 201 ) {
          this.$emit("orderUpdated", orderObj)
        }
      } catch (error) {
        this.baseIndex += 50 // on error
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
