var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{key:_vm.baseIndex,staticClass:"table-rounded datatable-height",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.items,"loading":_vm.loading,"item-key":"name","fixed-header":"","disable-sort":""},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody","handle":".dragg-item","animation":200,"ghost-class":"ghost-drag-item"},on:{"end":_vm.onDropCallback}},_vm._l((props.items),function(item,index){return _c('data-table-row-handler',{key:index,attrs:{"item":item,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.dragg",fn:function(ref){return [_c('v-btn',{staticClass:"action-btn dragg-item",attrs:{"title":"Mover","small":"","icon":""},on:{"click":function () {}}},[_c('svg',{staticStyle:{"width":"20px","height":"20px"},attrs:{"width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M4 22h-4v-4h4v4zm0-12h-4v4h4v-4zm0-8h-4v4h4v-4zm3 0v4h17v-4h-17zm0 12h17v-4h-17v4zm0 8h17v-4h-17v4z"}})])])]}},{key:"item.order",fn:function(ref){return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.upload",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.loadImage(item.upload),"target":"_blank","icon":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDownload))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"short-description",attrs:{"title":item.description}},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('CrudButtons',{attrs:{"draggControls":""},on:{"edit":function($event){return _vm.$emit('edit', item)},"remove":function($event){return _vm.$emit('remove', item)},"sendToTheEnd":function($event){return _vm.$emit('sendToTheEnd', Object.assign({}, item, {init_order: 'end'}))},"sendToTheInit":function($event){return _vm.$emit('sendToTheInit', Object.assign({}, item, {init_order: 'start'}))}}})],1)]}}],null,true)})}),1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }