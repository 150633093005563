import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const useTransparencySubtypesTypes = (idSubtype) => {

    // base data
    const dialog = ref(false);
    const transparencySubtype = ref(null);
    const transparencyContentEdited = ref(null);
    const transparencyContent = ref([]);
    const search = ref("");
    const loading = ref(false);

    // computed properties
    const titleDialog = computed(() =>
        transparencyContentEdited.value ? "Editar Contenido de Transparencia" : "Agregar Contenido de Transparencia"
    );
    const subtitleDialog = computed(() =>
        transparencyContentEdited.value ?
            "Rellene los campos correctamente para modificar los datos del Contenido de Transparencia" :
            "Rellene los campos correctamente para validar los datos del Contenido de Transparencia"
    );

    const openDialog = () => {
        dialog.value = true;
    }
    const changeSearch = (ev) => {
        search.value = ev
    }
    const openEdit = (transparencyContent) => {
        dialog.value = true;
        transparencyContentEdited.value = { ...transparencyContent };
    }
    const closeDialog = () => {
        dialog.value = false;
        transparencyContentEdited.value = null;
    }

    // methods
    const getSubtype = async () => {
        loading.value = true;
        const { data, status } = await store.dispatch('getSubtype', idSubtype);
        loading.value = false;
        if (status != 200 && status != 201) return;
        transparencySubtype.value = data;
        switch (data.type) {
            case 'table':
                getTransparencyTableBySubtype();
                break;
            case 'cards':
                getTransparencyCardsBySubtype();
                break;
        }
    }


    const getTransparencyTableBySubtype = async () => {
        loading.value = true;
        const { data, status } = await store.dispatch('getTransparencyContentTable', idSubtype);
        loading.value = false;
        transparencyContent.value = data;
    }

    const getTransparencyCardsBySubtype = async () => {
        loading.value = true;
        const { data, status } = await store.dispatch('getTransparencyContentCards', idSubtype);
        loading.value = false;
        // console.log(data)
        transparencyContent.value = data;
    }

    const createTransparencyContent = (content) => {
        switch (transparencySubtype.value.type) {
            case 'table':
                createTransparencyTableSubtype(content);
                break;
            case 'cards':
                createTransparencyCardsBySubtype(content);
                break;
        }
    }


    const createTransparencyTableSubtype = async (content) => {
        loading.value = true;
        const { data, status } = await store.dispatch('createTransparencyContentTable', content);
        loading.value = false;
        closeDialog();
        getTransparencyTableBySubtype();
    }


    const createTransparencyCardsBySubtype = async (content) => {
        loading.value = true;
        const { data, status } = await store.dispatch('createTransparencyContentCards', content);
        loading.value = false;
        // console.log('createCards')
        closeDialog();
        getTransparencyCardsBySubtype();
    }


    const updateTransparencyContent = (content) => {
        switch (transparencySubtype.value.type) {
            case 'table':
                updateTransparencyTableSubtype(content);
                break;
            case 'cards':
                updateTransparencyCardsBySubtype(content);
                break;
        }
    }

    const updateTransparencyTableSubtype = async (content) => {
        loading.value = true;
        const { data, status } = await store.dispatch('updateTransparencyContentTable', content);
        loading.value = false;
        // console.log(dialog.value)
        closeDialog();
        getTransparencyTableBySubtype();
    }

    const updateTransparencyCardsBySubtype = async (content) => {
        loading.value = true;
        const { data, status } = await store.dispatch('updateTransparencyContentCards', content);
        loading.value = false;
        closeDialog();
        getTransparencyCardsBySubtype();
    }

    const removeTransparencyTableBySubtype = async (content) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        loading.value = true;
        const { data, status } = await store.dispatch('removeTransparencyContentTable', content);
        loading.value = false;
        getTransparencyTableBySubtype();
    }


    const removeTransparencyCardsBySubtype = async (content) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        loading.value = true;
        const { data, status } = await store.dispatch('removeTransparencyContentCards', content);
        loading.value = false;
        getTransparencyCardsBySubtype();
    }

    const sortTransparencyContent = () => {
      transparencyContent.value.sort((a, b) => {
        return parseFloat(b.order) - parseFloat(a.order)
      })
      console.log(transparencyContent.value);
    }

    const updateTransparencyItemOnOrder = (obj) => {
      const idx = transparencyContent.value.findIndex(item => item.id == obj.id)
      if (idx > -1) {
        transparencyContent.value.splice(idx, 1, obj)
      }
      sortTransparencyContent()
    }


    getSubtype();


    return {
        dialog,
        transparencySubtype,
        transparencyContentEdited,
        transparencyContent,
        search,
        loading,
        // computed
        titleDialog,
        subtitleDialog,
        openDialog,
        closeDialog,
        openEdit,
        changeSearch,
        // methods
        createTransparencyContent,

        updateTransparencyContent,
        updateTransparencyItemOnOrder,
        updateTransparencyTableSubtype,

        removeTransparencyTableBySubtype,
        removeTransparencyCardsBySubtype,

    };
}

export default useTransparencySubtypesTypes;
